import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { addToCart, getCartInfo } from '../actions/cartActions';
import { useDispatch, useSelector } from 'react-redux';
import * as ScanditSDK from 'scandit-sdk';
import { BarcodePicker } from 'scandit-sdk';
import Scanner from './Scanner';
import CartLength from './CartLength';
import useAxiosInterceptors from '../axios/useAxios';
import { addUser } from '../actions/userActions';
import { Toast } from './Toast';
import { scanDisplay } from '../actions/scanActions';
import FooterCart from './FooterCart';
import Menu from './Menu';
import { ACCOUNT, HOME, NUMBER_SCREEN, SCAN } from '../constants/routes';
import BackButton from './button/BackButton';
import { formatPrice } from '../utils/StringUtils';
import { ReloadPage } from './tile/ReloadPage';
import { vidercache } from '../utils/ClearCache';
import ScannerTest from '../scanner/ScannerTest';
import closeArrow from '../styles/img/account/closeArrow.svg'
import successLottie from '../styles/lottie/success.json';
import Lottie from 'lottie-react';
import { isAppPWA } from '../utils/helper-functions';
import { confirmWarning, notifyError, notifyInfo, notifySuccess, notifyWarning } from '../utils/Notify';
import PrimaryButton from './button/PrimaryButton';
import Config from '../axios/Config';
import { addFidCard } from '../actions/userFidelityActions';
import ScanditInit from './Scandit/Sandit.js';
import PullToRefresh from 'pulltorefreshjs';
import ReactDOMServer from 'react-dom/server';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RatingStarFixe from './Rating_star_Fixe.jsx';
import { useTheme } from 'styled-components';
import * as Sentry from '@sentry/react';

const Scan = () => {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();
  const { cartItems } = useSelector((state) => state.cart);
  const { store } = useSelector((state) => state.store);
  const user = useSelector((state) => state.user);
  const scanTimer = 1000;
  const focusref = useRef(null);
  const axiosInstance = useAxiosInterceptors();
  const axiosInstance2 = Config();
  const dispatch = useDispatch();
  const [access, setAccess] = useState(true);
  const [scanner, setScanner] = useState(false);
  const [Code, setCode] = useState();
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState(false);
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [recaptchaResultToken, setRecaptchaResultToken] = useState('');

  const [popup, setPopup] = useState(false);
  const [qty, setQty] = useState(1);
  const [overflowStyle, setOverflowStyle] = useState('overflow-hidden h-full');
  //const [isOnline, setIsOnline] = useState(navigator.onLine);
  //const prevOnlineStatus = useRef(isOnline);
  const cart = useSelector((state) => state.cart);
  const [isNavbarHidden, setIsNavbarHidden] = useState(false);
  const countItems = CartLength();
  const [isPaused, setIsPaused] = useState(
    countItems >= store?.item_limit ? true : false
  );
  const [isDestroy, setIsDestroy] = useState(false);
  const [cartFullPopup, setCartFullPopup] = useState(false);
  const [isScanditLoaded, setIsScanditLoaded] = useState(false);
  const [seeReloadButton, setSeeReloadButton] = useState(false);
  const animProductRef = useRef(null);
  const [fidCardSuccess, setFidCardSuccess] = useState(false);
  const [rating, setRating] = useState(null);
  const [ratingCount, setRatingCount] = useState(null);
  window.scrollTo(0, 0);
  document.body.scrollTop = 0;

  // hooks
  const isPWA = isAppPWA();
  const handleClosePopupFid = (redirection) => {
    if (redirection === 'account') {
      localStorage.setItem('PREV_LOGIN_PAGE', SCAN);
      navigate(NUMBER_SCREEN, { state: { prevPage: SCAN } })
    } else {
      setFidCardSuccess(true);
      setTimeout(() => {
        setIsPaused(false);
        setPopup(false)
      }, 2400);
    }
  }
  const handleJustClosePopupFid = () => {
    setPopup(false);
    setIsPaused(false);
    setFidCardSuccess(false);
  }
  const handleScanPause = (scanPause) => {
    setIsPaused(scanPause);
  };
  useEffect(() => {
    if (!cartItems || !cartItems._id) {
      navigate('/');
    }
    if (cartItems?.isPaid) {
      if (store && store.id) {
        navigate(`/?ok=${store.id}`);
      } else {
        navigate('/');
      }
    }
  }, [cartItems]);

  useEffect(() => {
    if (countItems >= store?.item_limit) {
      setIsPaused(true);
      setCartFullPopup(true);
    } else {
      setIsPaused(false);
      setCartFullPopup(false);
    }
  }, [countItems]);
  const ref = useRef();

  console.log(isPaused);
  const refreshScan = () => {
    // setProduct()
    setAccess(true);
  };

  useEffect(async () => {
    await ScanditInit().then(() => {
      setIsScanditLoaded(true)
    }).catch((err) => {
      console.log('Error in ScanditInit:: ', err)
    })
  }, []);

  const getScanSettings = () => {
    return new ScanditSDK.ScanSettings({
                                         enabledSymbologies: ['ean8', 'ean13', 'upca', 'code128', 'qr'],
                                         codeDuplicateFilter: 2500,
                                         searchArea: { x: 0.025, y: 0.23, width: 0.95, height: 0.35 },
                                         maxNumberOfCodesPerFrame: 1,
                                         globPatterns: ['**/*.{css,html,ico,png,svg,woff2}', '**/*.{wasm,js}'], // Be sure to add also .wasm
                                         maximumFileSizeToCacheInBytes: 6 * 1024 * 1024, // Increase size cache up to 6mb
                                         ignoreURLParametersMatching: [/^v/], // Ignore ?v=x.x.x query string param when using importScripts

                                       });
  };

  const incQty = () => {
    setQty(qty + 1);
  };
  const decQty = () => {
    setQty(qty - 1);
  };

  const deployScan = () => {
    if (nom.length > 0 && prenom.length > 0) {
      dispatch(addUser(nom, prenom, ''));
      localStorage.setItem('scanner', true);
      setScanner(true);
      setOverflowStyle('overflow-hidden');
    } else Toast('error', 'YOU SHALL NOT PASS');
  };

  const goBackUser = () => {
    console.log('user', user);
    if (user && user._id) {
      navigate(ACCOUNT);
    } else {
      navigate(HOME);
    }
  };

  useEffect(() => {
    const handleChange = () => {
      dispatch(scanDisplay(false));
    };
    return () => {
      window.removeEventListener('beforeunload', handleChange());
    };
  }, []);

  const addToCartHandler = () => {
    setLoading(false);
    if (countItems == 4) {
      console.log('y\'en a 4');
      setIsPaused(true);
    }

    addToCartAnim();
  };

  async function addToCartAnim() {
    //setIsPaused(true);
    try {
      const footerCart = document.getElementById('footerCart');

      footerCart.classList.remove('shake');
      setIsNavbarHidden(true);
      getProductOffset();

      setTimeout(() => {
        if (product) {
          dispatch(addToCart(cartItems._id, product, qty, axiosInstance));
          setCode(0);
        }
        setProduct(false);
        footerCart.classList.add('shake');
      }, '750');

      footerCart.classList.remove('shake');
      console.log('fin anim cart');
    } catch (e) {
      console.log(e)
      Sentry.captureException(e);
    }

  }

  const getProductOffset = () => {
    try {
      if (product) {
        const element = document.getElementById('product_img');
        const offset = element.getBoundingClientRect();
        const animProduct = animProductRef.current;
        // Set the position of the div based on offset values
        animProduct.style.top = `${offset.top}px`;
        animProduct.style.left = `${offset.left}px`;
        const footerCart = document.getElementById('footer-cart');
        if (footerCart) {
          const cartOffset = footerCart.getBoundingClientRect();
          let translateXValue = 0;
          offset.left - cartOffset.left > 10
            ? (translateXValue = -(offset.left - cartOffset.left))
            : (translateXValue = 0);
          let translateYValue = 0;
          offset.top - cartOffset.top > 10
            ? (translateYValue = -(offset.top - cartOffset.top))
            : (translateYValue = 0);
          if (translateXValue !== 0 || translateYValue !== 0) {
            animProduct.style.setProperty(
              '--translate-y',
              `${translateYValue}px`
            );
          }

          animProduct.style.setProperty('--translate-x', `${translateXValue}px`);
        }
      }
    } catch (e) {
      console.log(e)
      Sentry.captureException(e);
    }

  };

  async function getProduct() {
    if (Code) {
      try {
        //setIsPaused(true)
        //alert("hello");
        setLoading(true);
        setIsNavbarHidden(false);
        console.log(Code);
        const { data } = await axiosInstance.get(
          `/products/${cartItems.storeId}/${Code.symbology}/${Code.data}`,
          {
            params: {
              orderId: cartItems._id,
            },
          }
        );
        if (data.rating && data.ratingCount) {
          console.log(data.rating)
          setRating(data.rating)
          setRatingCount(data.ratingCount)
        }

        setProduct(data);
        setLoading(false);

        //setIsPaused(false)
        // setAccess(false);
        setQty(1);
        setTimeout(refreshScan, scanTimer);
        getProductOffset();
      } catch (err) {
        console.log(err);
        setLoading(false);
        setCode();
        alert('Article non trouvé, réessayez !');
        setAccess(true);
      }
    }
  }

  // useEffect(() => {
  //   if (loading) {
  //     setIsPaused(true);
  //   } else {
  //     setIsPaused(false);
  //   }
  // }, [loading]);
  const onAddFidCardSuccess = (fidCardNum, data) => {
    if (data?.fidCard[0]?.fidelityInfo?.statutIdentification === 'OK') {
      setIsPaused(true);
      if (user && user._id) {
        navigate(SCAN, { state: { fidCardNum: fidCardNum } });
        notifySuccess('Carte ajoutée !');
        setIsPaused(false);
      } else {
        setPopup(true);
      }
    } else {
      setIsPaused(false);
      notifyError('Erreur lors de l\'ajout de la carte');
    }
    setLoading(false);
    setCode();
  };

  useEffect(() => {
    if (Code?.symbology === 'code128' && store.api === 'match') {
      setLoading(true);
      dispatch(
        addFidCard(
          axiosInstance2,
          user,
          cartItems,
          Code.data,
          recaptchaResultToken,
          (data) => onAddFidCardSuccess(Code.data, data),
          setLoading
        )
      );

    } else {
      getProduct();
    }
  }, [Code]);

  useEffect(() => {
    if (product) {
      // addToCartHandler()
      // setCode()
    }
  }, [product]);

  const productPopup = () => {
    console.log('productPopup');
    if (product) {
      setProduct(false);
      setCode(0);
    }
  };

  const handleCheckMenu = (checkMenu) => {
    console.log('Checkbox payment changed:', checkMenu);
    product.menu = checkMenu;
  };

  useEffect(() => {
    focusref.current.focus();
    setTimeout(() => {
      setSeeReloadButton(true);
    }, 6000);
  }, []);

  const handleShowFooter = () => {
    ref.current.onClickInfooter();
  };
  const reloadPageRef = useRef(null);

  // Fonction pour appeler le reset du timer
  const handleResetToaster = () => {
    if (reloadPageRef.current) {
      console.log('resetTimer');
      reloadPageRef.current.resetJustTimer();
    }
  };

  return (
    <>
      {cartItems ? (
        <>
          <div className="store_adresse">
            {store && store.id ? <>
              <div className="store_name">
                {store.name}
              </div>
              {store.address}</> : <></>}
          </div>
          <div ref={focusref} id="scan-main">
            <div className="min-w-full min-h-full overflow-hidden text-white bg-black ">
              <>

                <div className={`popup-fid-scan-container  ${popup ? 'active' : ''}`}>
                  <div
                    className="popup-fid-scan-invite"
                    style={{ transform: 'translate(-50%, -50%)' }}
                  >
                    <img src={closeArrow} alt="close" className="popup-fid-scan-close"
                         onClick={handleJustClosePopupFid} />
                    <div className={`popup-fid-scan-question  ${fidCardSuccess ? '' : 'active'}`}>
                      <h1>
                        Tu es en mode invité
                      </h1>
                      <p>
                        Ta carte ne sera pas sauvegardé pour la prochaine fois
                      </p>
                      <button className="popup-fid-scan-ok" onClick={handleClosePopupFid}>Oui d'accord</button>
                      <PrimaryButton
                        label={'Je créé mon compte'}
                        onClick={() => handleClosePopupFid('account')}
                      ></PrimaryButton>

                    </div>
                    <div className={`popup-fid-scan-success ${fidCardSuccess ? 'active' : ''}`} onClick={() => {
                      setPopup(false);
                      setIsPaused(false);
                      setFidCardSuccess(false)
                    }}>
                      {fidCardSuccess && <Lottie
                        loop={false}
                        autoplay={true}
                        animationData={successLottie}
                        height={70}
                        width={70}
                      />}
                      <h1>Carte ajoutée avec succès</h1>

                    </div>

                  </div>
                </div>
                <div className={`backblur-fid-scan  ${popup ? 'active' : ''}`} onClick={() => {
                  setPopup(false);
                  setIsPaused(false);
                  setFidCardSuccess(false)
                }}></div>

                {loading ? (
                  <>
                    <div
                      className="absolute z-50 w-screen h-screen "
                      style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
                    >
                      <div className="z-50 absolute left-2/4 top-[33%]  -translate-x-2/4 ">
                        <div className="lds-spinner white">
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {seeReloadButton && <ReloadPage
                  ref={reloadPageRef}
                  titre="Problème de scanneur ?"
                  message={'Clique ici pour actualiser '}
                  onClick={vidercache}
                  position={'top'}
                />}
                {store && store.id ? (
                  <div className="store_name_and_logo">
                    <div className="store_logo">
                      <img src={store.logo} />
                    </div>
                    <div className="store_name">{store.name.split(' ')[0]}</div>
                  </div>
                ) : (
                  <></>
                )}
                {isPWA ? (
                  isScanditLoaded && (
                    <ScannerTest
                      onClick={productPopup}
                      sdk
                      preloadBlurryRecognition={true}
                      preloadEngine={true}
                      accessCamera={true}
                      guiStyle={BarcodePicker.GuiStyle.VIEWFINDER}
                      viewFinderArea={{
                        x: 0.2,
                        y: 0.23,
                        width: 0.6,
                        height: 0.1,
                      }}
                      onScan={(scanResult) => {
                        setCode(scanResult.barcodes[0]);
                        //scanResult.rejectCode(scanResult.barcodes[0]);
                      }}
                      pause={isPaused}
                      destroy={isDestroy}
                      onProcessFramze
                      scanSettings={getScanSettings()}
                      videoFit={BarcodePicker.ObjectFit.COVER}
                      playSoundOnScan={true}
                      enableCameraSwitcher={false}
                      enablePinchToZoom={false}
                      enableTapToFocus={true}
                      enableTorchToggle={false}
                      mirrorImage={true}
                      targetScanningFPS={10}
                      zoom={0}
                      addTopMargin={-60}
                      size={215}
                    />
                  )
                ) : (
                  isScanditLoaded && (
                    <Scanner
                      onClick={productPopup}
                      preloadBlurryRecognition={true}
                      preloadEngine={true}
                      accessCamera={true}
                      guiStyle={BarcodePicker.GuiStyle.VIEWFINDER}
                      viewFinderArea={{
                        x: 0.2,
                        y: 0.23,
                        width: 0.6,
                        height: 0.1,
                      }}
                      onScan={(scanResult) => {
                        setRating(null);
                        setCode(scanResult.barcodes[0]);
                        handleResetToaster();
                        //scanResult.rejectCode(scanResult.barcodes[0]);
                      }}
                      pause={isPaused}
                      destroy={isDestroy}
                      onProcessFramze
                      scanSettings={getScanSettings()}
                      videoFit={BarcodePicker.ObjectFit.COVER}
                      playSoundOnScan={true}
                      enableCameraSwitcher={false}
                      enablePinchToZoom={false}
                      enableTapToFocus={true}
                      enableTorchToggle={false}
                      mirrorImage={true}
                      targetScanningFPS={10}
                      zoom={0}
                    />
                  )

                )}
                {/* {scan()} */}
                {/* {store.id === '65e6388eb6667e3400b5b8d8' || store.id === '65b8bde65a0ef81ff30473bf' ? ( */}

                {/*) : ( */}

                {/*)}*/}

                <BackButton onClick={goBackUser} isAbsolute isWhite />
                <div className="absolute w-full h-fit text-center m-auto top-[60%] overflow-hidden">
                  <p
                    className="text-lg w-screen text-white relative z-[1000]"
                    onClick={productPopup}
                  >
                    Scanne le code-barre de tes produits
                  </p>
                </div>
                {cartFullPopup ? (
                  <div
                    id="cartFullPopup"
                    className="absolute top-0 left-0 flex items-center justify-center h-full min-w-full min-h-full overflow-hidden z-[100]"
                  >
                    <div
                      id=""
                      className=" bg-white w-3/4 flex flex-col gap-8 p-8 rounded-[12px] "
                      style={{
                        boxShadow: '0 0 0 100vmax rgb(0 0 0 / 65%)',
                      }}
                    >
                      <div className="flex flex-col items-center justify-center gap-10 ">
                        <p className="mt-4 text-2xl text-center text-black geomanistBold">
                          Ton panier est plein, supprimez des articles pour
                          continuer à scanner.
                        </p>
                      </div>

                      <button
                        className="relative w-full py-6 mt-4 text-center text-black rounded-full pikko-btn justify-self-end">
                        <div
                          className="text-2xl text-black"
                          onClick={handleShowFooter}
                        >
                          Se rendre dans le panier
                        </div>
                      </button>

                      <div className="flex items-center justify-around"></div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <FooterCart handleResetToaster={handleResetToaster} scanPause={handleScanPause} ref={ref}
                            theme={theme} />
              </>

              <>
                {product ? (
                  <>
                    <div
                      className="fixed top-0 left-0 z-20 w-screen h-screen bg-black bg-opacity-70 backdrop-filter1"
                      onClick={productPopup}
                    ></div>
                    <div className="fixed bottom-[200px] w-screen px-4 z-[1000]">
                      <div
                        ref={animProductRef}
                        style={{ '--translate-x': 0, '--translate-y': 0 }}
                        className={`footer-navbar  ${
                          isNavbarHidden
                            ? 'cart-item cart-item-anim visible'
                            : 'hidden'
                        }
                        rounded-full bg-white w-28 h-28 flex items-center justify-center"
                        }`}
                      >
                        <div
                          className="absolute flex items-center justify-center w-24 h-24 inset-x-2/4 inset-y-2/4 -translate-y-2/4 -translate-x-2/4">
                          <img
                            src={product.image}
                            className="max-w-full max-h-full p-2 rounded-full"
                            alt=""
                          />
                        </div>
                      </div>

                      <div
                        id="popup_product"
                        className={`footer-navbar ${
                          isNavbarHidden
                            ? 'slide-down bg-white py-4 px-8 flex flex-col justify-evenly items-center rounded-[16px]'
                            : 'bg-white py-4 px-8 flex flex-col justify-evenly items-center rounded-[16px]'
                        }`}
                      >
                        <div className="flex items-center justify-between w-full pb-4 dashed">
                          <div className="flex items-center justify-start h-24">
                            <img
                              id="product_img"
                              src={product.image}
                              className="object-contain w-24 h-24"
                            ></img>
                            <div className="flex flex-col items-start justify-center h-full py-4 ml-4 ">
                              <div className="h-full text-left min-30">
                                <p className="mb-1 text-xl text-[#909090] ">
                                  {product.name}
                                </p>
                                <p className="text-2xl text-black">
                                  <strong>
                                    {' '}
                                    {formatPrice(product.price)}{' '}
                                  </strong>
                                </p>
                              </div>
                              <div>
                                {rating ? (
                                  <div className="product-rating-scan">
                                    <span>{rating}</span>
                                    <div className="flex items-center justify-center">
                                      <RatingStarFixe
                                        starClassName="w-6"
                                        rating={rating}
                                      />
                                    </div>
                                    <div className="rating-count">
                                      ({ratingCount})
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>

                          {qty < 2 && countItems + qty < store.item_limit ? (
                            <div
                              className="min-30_price rounded-full border-solid border-[#0000000e] px-6 py-1 flex items-center text-2xl relative">
                              {qty}
                              <button
                                className="top-1 border-none pl-4  text-[2.5rem] relative top-px relative "
                                onClick={() => incQty()}
                              >
                                +
                              </button>
                            </div>
                          ) : (
                            <>
                              <div
                                className="min-30_price rounded-full border-solid border-[#0000000e] px-6 py-1 flex items-center text-2xl relative">
                                {qty >= 2 ? (
                                  <button
                                    className="border-none text-[2.5rem]  pr-4 relative "
                                    onClick={() => decQty()}
                                  >
                                    -
                                  </button>
                                ) : (
                                  <></>
                                )}
                                {qty}
                                {countItems + qty >= store.item_limit ? (
                                  <></>
                                ) : (
                                  <button
                                    className="top-1 border-none pl-4 text-[2.5rem] relative top-px relative "
                                    onClick={() => incQty()}
                                  >
                                    +
                                  </button>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                        <Menu
                          store={store}
                          product={product}
                          onCheckboxChange={handleCheckMenu}
                        />
                        <div className="flex w-full">
                          <button
                            className="mt-5 mb-4 py-6 justify-self-end payer text-[1.7rem] geomanistMedium w-full"
                            onClick={() => addToCartHandler()}
                          >
                            Ajouter au panier
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Scan;
