import { useEffect, useState } from 'react';

export const Streaks = ({ user }) => {
    const [daysStreak, setDaysStreak] = useState([]);
    const [currentStreak, setCurrentStreak] = useState(0);
    const daysOfWeek = ["lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi", "dimanche"]; // Days in French for Mon to Sun

    useEffect(() => {
        if (user.streak) {
            setCurrentStreak(user.streak.currentStreak || 0);
            setDaysStreak(user.streak.daysUsed || []);
        }
    }, [user]);

    return (
        <div className="streaks">
            <div className="record-title">
                <h2>Série de {currentStreak} jour{currentStreak > 1 ? 's' : ''}</h2>
                {currentStreak > 0 ? (
                    <img src={'https://storage.googleapis.com/sb_pikkopay_webapp/themes/logos/default/flamme.png'}
                         alt="doubleArrow" />
                ) : (
                    <img src={'https://storage.googleapis.com/sb_pikkopay_webapp/themes/logos/default/flamme-off.png'}
                         alt="doubleArrow" />
                )}
            </div>
            <div className="streak-days">
                {daysOfWeek.map((day, index) => (
                    <div
                        key={index} // Unique key prop added here
                        className={`days-container ${daysStreak.includes(day) ? "active" : ""}`}
                    >
                        {day.split("")[0].toUpperCase()}
                        <div
                            className={`day ${daysStreak.includes(day) ? "active" : ""}`}
                        />
                    </div>
                ))}
            </div>
            <p className="description-streak">Fais tes paiements avec pikkopay pour obtenir des récompenses à chaque palier atteint</p>
        </div>
    );
};
